import { CompanyTypeEnum } from '@smarttypes/core';
import { GuestSorting } from '@smarttypes/hotel';

import { BASE_ENV, GuestTabsEnum, IBaseEnv, UI_GUESTS_FILTERS_DEFAULT } from './base-env';

export const environment: IBaseEnv = {
  ...BASE_ENV,
  poweredBy: false,
  production: true,
  releaseName: 'smart-chat',
  releasePhase: 'preproduction',
  registrationEnabled: false,
  canEditRooms: true,
  hasLeads: false,
  mediaLibrary: 'https://smart-core-api.smarthotelapi.com/media-library',
  appViewUrl: 'https://app-views-gjpvjwn2zq-ew.a.run.app',
  googleKey: 'AIzaSyCD7gTxbQ7a0SFNSgTzL_WfcCdvGtcrzrc',
  apiUrl: 'https://smart-core-api.smarthotelapi.com/',
  gtmId: 'GTM-KC2HX8D',
  jwt: {
    allowedDomains: ['localhost:4200'],
    disallowedRoutes: [
      new RegExp(/smart-core-public-api/),
      new RegExp(/account\/login/),
      new RegExp(/account\/refresh/),
      new RegExp(/assets/),
    ],
  },
  modules: {
    pushNotifications: {
      enabled: false,
    },
  },
  smartChatDemoUrl: 'https://sh-smart-ai-demo-page.web.app',
  ui: {
    user: {
      login: {
        showSelectWhatsApp: true,
      },
    },
    canShowUpgradeArrow: true,
    showAppsBadge: true,
    flatRoomName: false,
    top: {
      enabled: true,
      account: true,
    },
    sidebar: {
      enabled: true,
      companySwitcher: true,
    },
    unifiedInbox: {
      header: true,
      createRequest: true,
      guests: true,
      payments: true,
      sendTemplate: true,
      reservationStatus: false,
      assignUser: true,
    },
    qrMaterials: {
      identification: true,
      editLink: true,
    },
    registrationFormEditor: {
      qrCodeBox: true,
      signature: true,
    },
    massMessaging: {
      filters: {
        status: ['arrivals', 'inHouse', 'todayCheckedOut', 'checkedOut'],
        tags: true,
        nationality: true,
        rooms: false,
        guestAddDate: true,
        marketingConsents: true,
      },
    },
    requests: {
      filters: {
        category: true,
        department: false,
        commonName: false,
        ident: false,
      },
    },
    visitorsPlaceModule: {
      enabled: true,
    },
    showVisitorRoomInfo: false,
    smartAutomation: {
      forceCheckinEnable: false,
      skipRoomCheck: false,
      settings: {
        enable: true,
        entryInstruction: true,
        channels: true,
      },
    },
    guests: {
      reservationStatus: true,
      editable: true,
      sendEntryCodes: true,
      journeyDetails: true,
      forceActionButtonsInList: false,
      multipleReservations: true,
      filters: {
        [CompanyTypeEnum.hotel]: {
          ...UI_GUESTS_FILTERS_DEFAULT,
          apartmentLeft: false,
        },
        [CompanyTypeEnum.apartments]: UI_GUESTS_FILTERS_DEFAULT,
        [CompanyTypeEnum.smartMall]: {
          ...UI_GUESTS_FILTERS_DEFAULT,
          apartmentLeft: false,
          communicationChannels: false,
          checkInDate: false,
          checkedOutDate: false,
          guestAreaVisitedDate: true,
          createdAtDate: true,
          reservationStatus: false,
        },
        [CompanyTypeEnum.companyIntranet]: {
          ...UI_GUESTS_FILTERS_DEFAULT,
          apartmentLeft: false,
          communicationChannels: false,
          checkInDate: false,
          checkedOutDate: false,
          guestAreaVisitedDate: true,
          createdAtDate: true,
          reservationStatus: false,
          building: true,
          department: true,
        },
      },
      tabsSorting: {
        default: {
          [GuestTabsEnum.arrivals]: {
            default: GuestSorting.checkInAsc,
            allowed: [GuestSorting.checkInAsc, GuestSorting.checkedOutAsc, GuestSorting.changedDesc],
          },
          [GuestTabsEnum.inHouse]: {
            default: GuestSorting.checkInAsc,
            allowed: [
              GuestSorting.checkInAsc,
              GuestSorting.checkInDesc,
              GuestSorting.checkedOutAsc,
              GuestSorting.changedDesc,
            ],
          },
          [GuestTabsEnum.blocked]: {
            default: GuestSorting.checkInAsc,
            allowed: Object.values(GuestSorting),
          },
          [GuestTabsEnum.freshCheckedOut]: {
            default: GuestSorting.checkInDesc,
            allowed: [GuestSorting.checkInDesc, GuestSorting.changedDesc],
          },
          [GuestTabsEnum.checkedOut]: {
            default: GuestSorting.checkInDesc,
            allowed: [GuestSorting.checkInDesc, GuestSorting.checkedOutDesc],
          },
          [GuestTabsEnum.cancelled]: {
            default: GuestSorting.checkInAsc,
            allowed: [
              GuestSorting.checkInAsc,
              GuestSorting.checkInDesc,
              GuestSorting.checkedOutAsc,
              GuestSorting.checkedOutDesc,
              GuestSorting.changedAsc,
              GuestSorting.changedDesc,
            ],
          },
        },
        [CompanyTypeEnum.apartments]: {
          [GuestTabsEnum.freshCheckedOut]: {
            default: GuestSorting.checkInDesc,
            allowed: [
              GuestSorting.checkInDesc,
              GuestSorting.apartmentLeftAtAsc,
              GuestSorting.apartmentLeftAtDesc,
              GuestSorting.changedDesc,
            ],
          },
        },
      },
    },
    apartmentEditor: {
      customSectionsEnable: true,
      syncButton: true,
      requiredFieldsWarning: true,
      importInformation: true,
      copyFeature: true,
      validation: true,
      requiredFields: [
        'apartmentNumber',
        'floorNumber',
        'country',
        'postalCode',
        'city',
        'streetAndHouseNumber',
        'googleMapsPin',
        'checkInFromTime',
        'checkOutUntilTime',
        'wifiName',
        'entryInstruction',
      ],
      fields: {
        base: [
          'apartmentNumber',
          'floorNumber',
          'country',
          'postalCode',
          'city',
          'streetAndHouseNumber',
          'googleMapsPin',
          'checkInFromTime',
          'checkInToTime',
          'checkOutUntilTime',
          'wifiName',
          'wifiPassword',
        ],
        stay: ['about', 'directions', 'amenities', 'parking', 'transfer', 'meals', 'cleaning', 'website'],
        entryInstruction: true,
      },
    },
  },
  registerPushNotifications: false,
};
